
.quality-course-container {
    .bodywrap-content {
        /*min-height: 802px;*/
        box-sizing: border-box;
        /*margin: 0 auto;*/
        /*height: calc(100% - 84px) !important;*/
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .container {
        /*width: 1220px;*/
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        .course-manage-title {
            color: #595959;
            font-size: 18px;
            margin: 15px 0 0;
            width: 1000px;
            font-weight: bold;
        }
        .row {
            display: flex;
            flex-wrap: wrap;
        }
        .res-container {
            display: flex;
            justify-content: start;
            margin-left: 0;
            .res-leftarea {
                width: 920px;
                margin: 0;
                .res-list {
                    .video-box {
                        margin: 15px 0 0 5px;
                        width: 916px;
                        height: 100%;
                    }
                    .pub-video-info .pub-name {
                        color: #333;
                        font-size: 20px;
                        padding: 15px 0;
                        border-bottom: 1px solid #E0E0E1;
                        margin: 0 5px;
                    }
                    .pub-course-info {
                        margin: 30px 5px 60px 5px;
                        .c-content-title {
                            font-size: 16px;
                            color: #666;
                            margin-bottom: 30px;
                            &::before {
                                content: '';
                                display: inline-block;
                                width: 3px;
                                height: 16px;
                                background: #E31D1A;
                                margin: 0 10px 0 0;
                                vertical-align: -2px;
                            }
                        }
                        .c-public-content {
                            color: #666666;
                            line-height: 24px;
                            p {
                                margin-top: 0;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
